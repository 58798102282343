/* global BigInt */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';
import StorefrontIcon from '@material-ui/icons/Storefront';
import {StoicIdentity} from '../ic/identity.js';
import extjs from '../ic/extjs.js';
import Alert from '@material-ui/lab/Alert';
import {useTheme} from '@material-ui/core/styles';
import ListingBuyForm from '../components/ListingBuyForm';
import {useSelector} from 'react-redux';
import Listing from '../components/Listing';
const perPage = 30;
const api = extjs.connect('https://icp0.io/');
const collections = [
  // {
  // canister: "bid2t-gyaaa-aaaah-qcdea-cai",
  // market : true,
  // name: "Haunted Hamsters",
  // route: "hauntedhamsters",
  // nftv : false,
  // mature: false,
  // commission: 0,
  // comaddress: "35b902472e845179b3d6ad9ff7079fee6bcadb4e0ca870230ba7a79757fa88fb",
  // blurb: false,
  // },
  {
    canister: 'gyuaf-kqaaa-aaaah-qceka-cai',
    market: true,
    name: 'Infernal Vampire Colony',
    route: 'ivc',
    nftv: false,
    mature: false,
    commission: 0,
    comaddress: '7243722b9db43a26170b2bbc065b02f5ca2d1836ddaaee5ef05fc043a01f9ed9',
    blurb: false,
  },
  {
    canister: 'nfvlz-jaaaa-aaaah-qcciq-cai',
    name: 'IC3D',
    route: 'ic3d',
    nftv: false,
    mature: false,
    commission: 0, //0.035,
    comaddress: '2455059d792289741fb4c3128be9dfcf25474e161923c78c37bd53c457b24e60',
    blurb: false,
  },

  // {
  // canister: "er7d4-6iaaa-aaaaj-qac2q-cai",
  // name: "MoonWalkers",
  // route: "moonwalkers",
  // nftv : false,
  // mature: false,
  // commission: 0,//0.035,
  // comaddress: "2455059d792289741fb4c3128be9dfcf25474e161923c78c37bd53c457b24e60",
  // blurb: false,
  // },
  {
    canister: 'ahl3d-xqaaa-aaaaj-qacca-cai',
    name: 'ICTuTs',
    route: 'ictuts',
    mature: false,
    commission: 0.025,
    comaddress: 'b53a735c40994ddbc7bb4f6dbfbf9b2c67052842241f1c445f2255bdf4bd8982',
    blurb: (
      <>
        We will have all of the 10,000 NFTs available for sale at launch, there will be 5 price
        tiers (Thirty NFTs will be reserved for the creators). We will try to distrube them fairly
        as possible by whitelisting.
      </>
    ),
  },
  {
    canister: 'e3izy-jiaaa-aaaah-qacbq-cai',
    name: 'Cronic Critters',
    route: 'cronics',
    mature: false,
    commission: 0.025,
    comaddress: 'c7e461041c0c5800a56b64bb7cefc247abc0bbbb99bd46ff71c64e92d9f5c2f9',
    blurb: (
      <>
        Cronics is a Play-to-earn NFT game being developed by ToniqLabs for the Internet Computer.
        Cronics incorporates breeding mechanics, wearable NFTs and a p2e minigame ecosystem and
        more. Join the{' '}
        <a href="https://t.me/cronic_fun" target="_blank" rel="noreferrer">
          Telegram group
        </a>{' '}
        or read more on{' '}
        <a
          href="https://toniqlabs.medium.com/cronics-breeding-and-supply-604fa374776d"
          target="_blank"
          rel="noreferrer"
        >
          Medium
        </a>
      </>
    ),
  },
  {
    canister: 'nbg4r-saaaa-aaaah-qap7a-cai',
    name: 'Starverse',
    route: 'starverse',
    mature: false,
    commission: 0.025,
    comaddress: 'c7e461041c0c5800a56b64bb7cefc247abc0bbbb99bd46ff71c64e92d9f5c2f9',
    blurb: false,
  },
  {
    canister: 'njgly-uaaaa-aaaah-qb6pa-cai',
    name: 'ICPuppies',
    route: 'icpuppies',
    mature: false,
    commission: 0, //0.035,
    comaddress: '9f76290b181807c5fa3c7cfcfca2525d578a3770f40ae8b14a03a4a3530368e2',
    blurb: (
      <>
        10,000 randomly generated 8-bit puppy NFTs. Join the{' '}
        <a href="discord.gg/A3rmDSjBaJ" target="_blank" rel="noreferrer">
          Discord
        </a>{' '}
        or follow us on{' '}
        <a href="https://twitter.com/ICPuppies" target="_blank" rel="noreferrer">
          Twitter
        </a>
      </>
    ),
  },
  {
    canister: 'bxdf4-baaaa-aaaah-qaruq-cai',
    name: 'ICPunks',
    route: 'icpunks',
    mature: false,
    commission: 0.035,
    comaddress: 'c47942416fa8e7151f679d57a6b2d2e01a92fecd5e6f9ac99f6db548ea4f37aa',
    blurb: (
      <>
        Are you down with the clown? Get your hands on the latest NFT to hit the Internet Computer!
        You can wrap and trade them on the Marketplace!{' '}
        <strong>Wrapped ICPunks are 1:1 wrapped versions of actual ICPunks</strong> - you can read
        more about how to wrap, unwrap, and how safe it is{' '}
        <a
          href="https://medium.com/@toniqlabs/wrapped-nfts-8c91fd3a4c1"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
      </>
    ),
  },
  {
    canister: '3db6u-aiaaa-aaaah-qbjbq-cai',
    name: 'IC Drip',
    route: 'icdrip',
    mature: false,
    commission: 0.025,
    comaddress: 'c7e461041c0c5800a56b64bb7cefc247abc0bbbb99bd46ff71c64e92d9f5c2f9',
    blurb: (
      <>
        IC Drip are randomly generated meta-commerce shopping carts for outfits and personas stored
        on chain. Stats, images, and other functionality are intentionally omitted for others to
        interpret. Feel free to use IC Drip in any way you want.{' '}
        <a href="https://dvr6e-lqaaa-aaaai-qam5a-cai.raw.icp0.io/" target="_blank" rel="noreferrer">
          IC Drip Website
        </a>
      </>
    ),
  },
  {
    canister: '73xld-saaaa-aaaah-qbjya-cai',
    name: 'Wing',
    route: 'wing',
    mature: true,
    commission: 0.025,
    comaddress: '1d978f4f38d19dca4218832e856c956678de0aa470cd492f5d8ac4377db6f2a2',
    blurb: (
      <>
        To escape from containment and restriction, releasing the stressors held so long in the
        body, Wings’s ego is jettisoned as she explores a more fundamental form of existence,
        expressing her humanity in this elemental piece.
        <br />
        She is framed within the themes of air, water, to take flight on chalky cliff tops
        overlooking distant horizons, to express the existential freedom that lives within. No
        borders.
        <br />
        And so through this series I invite the viewer to celebrate their own sovereignty of
        consciousness; to be bold as we emerge from our cocoons and open ourselves up to the world
        and each other again.
      </>
    ),
  },
  {
    canister: 'kss7i-hqaaa-aaaah-qbvmq-cai',
    name: 'ICelebrity',
    route: 'icelebrity',
    mature: false,
    commission: 0.035,
    comaddress: '8b6840cb0e67738e69dbb6d79a3963f7bd93c35f593a393be5cc39cd59ed993e',
    blurb: false,
  },
  {
    canister: 'k4qsa-4aaaa-aaaah-qbvnq-cai',
    name: 'Faceted Meninas',
    route: 'faceted-meninas',
    mature: false,
    commission: 0.02,
    comaddress: '12692014390fbdbb2f0a1ecd440f02d29962601a782553b45bb1a744f167f13b',
    blurb: (
      <>
        Faceted Meninas is a creature species that holds the power of the universe to act as a magic
        pillar giving their allies the essence of outer worlds to maximize their powers.
      </>
    ),
  },
  {
    canister: 'uzhxd-ziaaa-aaaah-qanaq-cai',
    name: 'ICP News',
    mature: false,
    route: 'icp-news',
    commission: 0.02,
    comaddress: 'c7e461041c0c5800a56b64bb7cefc247abc0bbbb99bd46ff71c64e92d9f5c2f9',
    blurb: false,
  },
  {
    canister: 'tde7l-3qaaa-aaaah-qansa-cai',
    name: 'Cronic Wearables',
    route: 'wearables',
    mature: false,
    commission: 0.025,
    comaddress: 'c7e461041c0c5800a56b64bb7cefc247abc0bbbb99bd46ff71c64e92d9f5c2f9',
    blurb: false,
  },
  {
    canister: 'gevsk-tqaaa-aaaah-qaoca-cai',
    name: 'ICmojis',
    route: 'icmojis',
    mature: false,
    commission: 0.02,
    comaddress: 'df13f7ef228d7213c452edc3e52854bc17dd4189dfc0468d8cb77403e52b5a69',
    blurb: false,
  },
  {
    canister: 'owuqd-dyaaa-aaaah-qapxq-cai',
    name: 'ICPuzzle',
    route: 'icpuzzle',
    mature: true,
    commission: 0.02,
    comaddress: '12692014390fbdbb2f0a1ecd440f02d29962601a782553b45bb1a744f167f13b',
    blurb: false,
  },
];
var cb = null;
const txfee = 10000;
const txmin = 100000;
const txcomm = 0.015;
var intv = false;
export default function Marketplace(props) {
  const currentPrincipal = useSelector(state => state.currentPrincipal);
  const identity = useSelector(state =>
    state.principals.length ? state.principals[currentPrincipal].identity : {},
  );
  const [listings, setListings] = React.useState([]);
  const accounts = useSelector(state => state.principals[currentPrincipal].accounts);
  const [page, setPage] = React.useState(1);
  const [listingBuyFormOpen, setListingBuyFormOpen] = React.useState(false);
  const [sort, setSort] = React.useState('price_asc');

  const handleChange = event => {
    setSort(event.target.value);
  };

  const listingBuyFormSubmit = d => {
    listingBuyFormClose();
    cb(d);
    cb = null;
  };

  const listingBuyFormClose = () => {
    setListingBuyFormOpen(false);
  };
  const showListingBuyForm = () => {
    setListingBuyFormOpen(true);
    return new Promise((resolve, reject) => {
      cb = resolve;
    });
  };

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    empty: {
      maxWidth: 800,
      margin: '0 auto',
      textAlign: 'center',
    },
    grid: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },

    largeIcon: {
      width: 60,
      height: 60,
    },
  };
  const _process = async () => {
    props.loader(true);
    await processPayments();
    props.loader(false);
  };
  const _updates = async () => {
    console.log('poll');
    refreshListings();
    setTimeout(_updates, 30 * 1000);
  };

  const processPayments = async () => {
    const id = StoicIdentity.getIdentity(identity.principal);
    const _api = extjs.connect('https://icp0.io/', id);
    for (var j = 0; j < collections.length; j++) {
      var payments = await _api.canister(collections[j].canister).payments();
      if (payments.length === 0) continue;
      if (payments[0].length === 0) continue;
      console.log('Payments found: ' + payments[0].length);
      var a, b, c, payment;
      for (var i = 0; i < payments[0].length; i++) {
        payment = payments[0][i];
        a = extjs.toAddress(identity.principal, payment);
        b = Number(await api.token().getBalance(a));
        c = Math.round(b * collections[j].commission);
        try {
          var txs = [];
          if (b > txmin) {
            txs.push(
              _api
                .token()
                .transfer(
                  identity.principal,
                  payment,
                  accounts[0].address,
                  BigInt(b - (txfee + c)),
                  BigInt(txfee),
                ),
            );
            if (collections[j].commission > 0) {
              txs.push(
                _api
                  .token()
                  .transfer(
                    identity.principal,
                    payment,
                    collections[j].comaddress,
                    BigInt(c - txfee),
                    BigInt(txfee),
                  ),
              );
            }
          }
          await Promise.all(txs);
          console.log('Payments removed successfully');
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const refreshListings = () => {
    api
      .canister('e3izy-jiaaa-aaaah-qacbq-cai')
      .listings()
      .then(listings => {
        setListings(listings);
      })
      .finally(() => {
        props.loader(false);
      });
  };
  const error = e => {
    props.alert('There was an error', e);
  };
  React.useEffect(() => {
    props.loader(true);

    _updates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={styles.root}>
      <Alert severity="warning">
        If you have not received ICP for sold NFTs through the marketplace you can trigger a manual
        withdraw here{' '}
        <Button onClick={_process} variant="contained" size={'small'} color={'primary'}>
          Withdraw ICP
        </Button>
      </Alert>
      {listings.length === 0 ? (
        <div style={styles.empty}>
          <Typography paragraph align="center">
            <StorefrontIcon style={styles.largeIcon} />
          </Typography>
          <Typography paragraph style={{paddingTop: 20, fontWeight: 'bold'}} align="center">
            There are no listings added to the marketplace. You can list NFTs for sale directly from
            your wallet!
          </Typography>
          <Button
            onClick={() => {
              props.loader(true);
              refreshListings();
            }}
            variant="contained"
            color="primary"
          >
            Refresh Listings
          </Button>
        </div>
      ) : (
        <>
          <div style={styles.empty}>
            <Typography paragraph style={{paddingTop: 20, fontWeight: 'bold'}} align="center">
              Buy and Sell NFTs from the Marketplace. You can list NFTs for sale directly from your
              wallet!
            </Typography>
            <Button
              onClick={() => {
                props.loader(true);
                refreshListings();
              }}
              variant="contained"
              color="primary"
            >
              Refresh Listings
            </Button>
          </div>
          <div style={{marginLeft: '20px', marginTop: '10px'}}>
            <FormControl>
              <Select value={sort} onChange={handleChange}>
                <MenuItem value={'price_asc'}>Price Ascending</MenuItem>
                <MenuItem value={'price_desc'}>Price Descending</MenuItem>
                <MenuItem value={'mint_number'}>Minting #</MenuItem>
                <MenuItem value={'type'}>Rare Type</MenuItem>
                <MenuItem value={'gri'}>Genetic Rarity Index</MenuItem>
              </Select>
            </FormControl>

            {listings.length > perPage ? (
              <Pagination
                style={{float: 'right', marginTop: '5px', marginBottom: '20px'}}
                size="small"
                count={Math.ceil(listings.length / perPage)}
                page={page}
                onChange={(e, v) => setPage(v)}
              />
            ) : (
              ''
            )}
          </div>
          <div style={styles.grid}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              {listings
                .slice()
                .sort((a, b) => {
                  switch (sort) {
                    case 'price_asc':
                      return Number(a[1].price) - Number(b[1].price);
                    case 'price_desc':
                      return Number(b[1].price) - Number(a[1].price);
                    case 'gri':
                      return Number(gridata[b[0]]) * 100 - Number(gridata[a[0]]) * 100;
                    case 'mint_number':
                      return a[0] - b[0];
                    case 'type':
                      var _a = a[2].nonfungible.metadata[0][30] % 41;
                      var _b = b[2].nonfungible.metadata[0][30] % 41;
                      if (_a === 2) _a = 1;
                      if (_a > 1) _a = 2;
                      if (_b === 2) _b = 1;
                      if (_b > 1) _b = 2;
                      var d = _a - _b;
                      if (d === 0) {
                        if (Number(a[1].price) > Number(b[1].price)) return 1;
                        if (Number(a[1].price) < Number(b[1].price)) return -1;
                      }
                      return d;
                    default:
                      return 0;
                  }
                })
                .filter((token, i) => i >= (page - 1) * perPage && i < page * perPage)
                .map((listing, i) => {
                  return (
                    <Listing
                      gri={gridata[listing[0]]}
                      refreshListings={refreshListings}
                      showListingBuyForm={showListingBuyForm}
                      loader={props.loader}
                      alert={props.alert}
                      error={error}
                      key={listing[0]}
                      listing={listing}
                      confirm={props.confirm}
                    />
                  );
                })}
            </Grid>
          </div>
          {listings.length > perPage ? (
            <Pagination
              style={{float: 'right', marginTop: '5px', marginBottom: '20px'}}
              size="small"
              count={Math.ceil(listings.length / perPage)}
              page={page}
              onChange={(e, v) => setPage(v)}
            />
          ) : (
            ''
          )}
        </>
      )}
      <ListingBuyForm
        open={listingBuyFormOpen}
        onSubmit={listingBuyFormSubmit}
        onClose={listingBuyFormClose}
      />
    </div>
  );
}

var gridata = [
  0.17, 0.819, 0.922, 0.72, 0.622, 0.251, 0.813, 0.3, 0.297, 0.618, 0.653, 0.826, 0.837, 0.736,
  0.459, 0.847, 0.738, 0.905, 0.875, 0.186, 0.271, 0.963, 0.649, 0.625, 0.985, 0.295, 0.71, 0.641,
  0.638, 0.085, 0.059, 0.764, 0.149, 0.901, 0.101, 0.992, 0.652, 0.95, 0.116, 0.797, 0.738, 0.791,
  0.57, 0.216, 0.73, 0.688, 0.97, 0.864, 0.205, 0.143, 0.227, 0.289, 0.805, 0.949, 0.606, 0.093,
  0.189, 0.651, 0.845, 0.853, 0.356, 0.029, 0.832, 0.083, 0.29, 0.584, 0.888, 0.82, 0.25, 0.719,
  0.792, 0.692, 0.914, 0.096, 0.04, 0.459, 0.653, 0.445, 0.571, 0.161, 0.372, 0.718, 0.8, 0.319,
  0.307, 0.685, 0.254, 0.789, 0.862, 0.922, 0.64, 0.572, 0.144, 0.25, 0.885, 0.018, 0.103, 0.85,
  0.889, 0.142, 0.948, 0.379, 0.021, 0.325, 0.661, 0.656, 0.928, 0.043, 0.43, 0.983, 0.396, 0.664,
  0.843, 0.498, 0.84, 0.04, 0.189, 0.144, 0.822, 0.682, 0.628, 0.099, 0.161, 0.632, 0.882, 0.528,
  0.369, 0.901, 0.069, 0.013, 0.871, 0.787, 0.046, 0.197, 0.627, 0.599, 0.248, 0.106, 0.371, 0.716,
  0.395, 0.807, 0.219, 0.537, 0.975, 0.613, 0.534, 0.425, 0.575, 0.413, 0.81, 0.654, 0.983, 0.456,
  0.202, 0.78, 0.979, 0.468, 0.58, 0.505, 0.771, 0.434, 0.908, 0.763, 0.327, 0.145, 0.924, 0.31,
  0.642, 0.162, 0.375, 0.811, 0.324, 0.962, 0.634, 0.358, 0.428, 0.003, 0.034, 0.414, 0.548, 0.566,
  0.993, 0.544, 0.934, 0.301, 0.971, 0.575, 0.142, 0.086, 0.464, 0.014, 0.169, 0.549, 0.344, 0.008,
  0.128, 0.851, 0.832, 0.306, 0.099, 0.128, 0.441, 0.723, 0.375, 0.805, 0.823, 0.364, 0.587, 0.096,
  0.581, 0.442, 0.706, 0.242, 0.774, 0.882, 0.597, 0.011, 0.589, 0.057, 0.984, 0.196, 0.595, 0.66,
  0.992, 0.797, 0.925, 0.449, 0.044, 0.811, 0.861, 0.275, 0.894, 0.863, 0.541, 0.79, 0.731, 0.455,
  0.522, 0.768, 0.653, 0.064, 0.229, 0.709, 0.395, 0.855, 0.489, 0.811, 0.586, 0.097, 0.342, 0.416,
  0.763, 0.589, 0.376, 0.291, 0.657, 0.314, 0.296, 0.567, 0.269, 0.411, 0.169, 0.992, 1, 0.642,
  0.137, 0.118, 0.752, 0.448, 0.173, 0.907, 0.843, 0.167, 0.207, 0.488, 0.122, 0.747, 0.607, 0.355,
  0.87, 0.688, 0.146, 0.622, 0.081, 0.26, 0.102, 0.436, 0.778, 0.782, 0.452, 0.293, 0.112, 0.245,
  0.991, 0.827, 0.757, 0.874, 0.426, 0.027, 0.024, 0.332, 0.539, 0.572, 0.32, 0.894, 0.086, 0.259,
  0.148, 0.51, 0.752, 0.59, 0.833, 0.892, 0.7, 0.863, 0.67, 0.135, 0.234, 0.367, 0.607, 0.851,
  0.221, 0.686, 0.52, 0.612, 0.346, 0.534, 0.882, 0.237, 0.001, 0.331, 0.556, 0.1, 0.389, 0.978,
  0.705, 0.15, 0.479, 0.38, 0.318, 0.962, 0.686, 0.58, 0.774, 0.645, 0.443, 0.962, 0.567, 0.868,
  0.651, 0.729, 0.782, 0.786, 0.425, 0.44, 0.815, 0.477, 0.247, 0.515, 0.095, 0.675, 0.722, 0.763,
  0.934, 0.824, 0.752, 0.311, 0.807, 0.242, 0.06, 0.827, 0.213, 0.307, 0.002, 0.702, 0.267, 0.593,
  0.808, 0.201, 0.379, 0.352, 0.491, 0.083, 0.941, 0.988, 0.781, 0.335, 0.882, 0.02, 0.608, 0.484,
  0.726, 0.065, 0.057, 0.767, 0.305, 0.654, 0.391, 0.479, 0.593, 0.083, 0.281, 0.429, 0.485, 0.784,
  0.397, 0.56, 0.058, 0.209, 0.107, 0.913, 0.206, 0.376, 0.934, 0.71, 0.329, 0.363, 0.585, 0.284,
  0.172, 0.895, 0.409, 0.545, 0.257, 0.454, 0.836, 0.727, 0.793, 0.019, 0.742, 0.068, 0.482, 0.011,
  0.91, 0.825, 0.284, 0.055, 0.765, 0.124, 0.194, 0.166, 0.087, 0.201, 0.434, 0.889, 0.647, 0.557,
  0.838, 0.361, 0.927, 0.778, 0.065, 0.892, 0.687, 0.643, 0.62, 0.112, 0.33, 0.396, 0.247, 0.244,
  0.042, 0.785, 0.018, 0.382, 0.414, 0.641, 0.516, 0.434, 0.735, 0.806, 0.242, 0.979, 0.728, 0.598,
  0.25, 0.525, 0.658, 0.39, 0.622, 0.901, 0.493, 0.551, 0.042, 0.599, 0.412, 0.455, 0.703, 0.903,
  0.09, 0.218, 0.639, 0.159, 0.853, 0.525, 0.996, 0.424, 0.859, 0.118, 0.496, 0.08, 0.529, 0.735,
  0.18, 0.592, 0.916, 0.866, 0.456, 0.677, 0.458, 0.373, 0.001, 0.351, 0.191, 0.112, 0.81, 0.637,
  0.387, 0.833, 0.958, 0.457, 0.306, 0.042, 0.555, 0.834, 0.222, 0.779, 0.239, 0.418, 0.195, 0.009,
  0.371, 0.088, 0.062, 0.627, 0.626, 0.416, 0.885, 0.877, 0.209, 0.075, 0.539, 0.052, 0.903, 0.747,
  0.897, 0.5, 0.099, 0.954, 0.878, 0.717, 0.527, 0.164, 0.269, 0.829, 0.246, 0.299, 0.798, 0.187,
  0.463, 0.097, 0.086, 0.637, 0.697, 0.91, 0.588, 0.569, 0.546, 0.409, 0.014, 0.406, 0.433, 0.749,
  0.428, 0.731, 0.682, 0.381, 0.2, 0.052, 0.102, 0.104, 0.951, 0.352, 0.202, 0.544, 0.444, 0.318,
  0.647, 0.646, 0.872, 0.535, 0.917, 0.813, 0.054, 0.775, 0.656, 0.687, 0.414, 0.101, 0.581, 0.922,
  0.513, 0.712, 0.48, 0.907, 0.691, 0.535, 0.171, 0.385, 0.863, 0.473, 0.083, 0.601, 0.1, 0.358,
  0.688, 0.76, 0.279, 0.265, 0.264, 0.179, 0.587, 0.982, 0.576, 0.348, 0.09, 0.53, 0.757, 0.065,
  0.762, 0.403, 0.382, 0.104, 0.04, 0.35, 0.74, 0.173, 0.829, 0.549, 0.341, 0.124, 0.927, 0.344,
  0.2, 0.377, 0.868, 0.946, 0.063, 0.419, 0.822, 0.645, 0.779, 0.148, 0.186, 0.689, 0.17, 0.968,
  0.55, 0.878, 0.603, 0.841, 0.362, 0.9, 0.117, 0.892, 0.051, 0.636, 0.34, 0.586, 0.585, 0.566,
  0.737, 0.639, 0.831, 0.653, 0.748, 0.968, 0.887, 0.158, 0.231, 0.111, 0.896, 0.226, 0.219, 0.835,
  0.041, 0.562, 0.928, 0.003, 0.695, 0.975, 0.042, 0.577, 0.77, 0.785, 0.807, 0.46, 0.733, 0.693,
  0.946, 0.635, 0.528, 0.339, 0.149, 0.348, 0.916, 0.95, 0.811, 0.992, 0.941, 0.028, 0.814, 0.512,
  0.816, 0.394, 0.678, 0.427, 0.261, 0.815, 0.13, 0.808, 0.796, 0.387, 0.914, 0.988, 0.371, 0.399,
  0.168, 0.494, 0.782, 0.769, 0.431, 0.503, 0.086, 0.862, 0.163, 0.256, 0.663, 0.586, 0.96, 0.533,
  0.673, 0.666, 0.484, 0.201, 0.631, 0.309, 0.662, 0.944, 0.933, 0.032, 0.192, 0.69, 0.106, 0.301,
  0.402, 0, 0.962, 0.474, 0.791, 0.131, 0.712, 0.265, 0.913, 0.376, 0.298, 0.436, 0.492, 0.449,
  0.552, 0.549, 0.259, 0.862, 0.383, 0.439, 0.746, 0.511, 0.794, 0.999, 0.32, 0.504, 0.775, 0.078,
  0.77, 0.84, 0.871, 0.531, 0.678, 0.557, 0.698, 0.977, 0.906, 0.819, 0.582, 0.414, 0.662, 0.432,
  0.619, 0.306, 0.889, 0.639, 0.146, 0.179, 0.619, 0.253, 0.787, 0.196, 0.208, 0.371, 0.839, 0.09,
  0.788, 0.175, 0.943, 0.812, 0.055, 0.579, 0.475, 0.756, 0.834, 0.719, 0.298, 0.179, 0.93, 0.356,
  0.955, 0.328, 0.961, 0.225, 0.952, 0.935, 0.539, 0.288, 0.554, 0.023, 0.6, 0.488, 0.272, 0.132,
  0.005, 0.93, 0.091, 0.137, 0.778, 0.312, 0.08, 0.015, 0.191, 0.306, 0.375, 0.804, 0.002, 0.184,
  0.528, 0.496, 0.994, 0.316, 0.251, 0.576, 0.461, 0.485, 0.062, 0.932, 0.544, 0.486, 0.025, 0.075,
  0.012, 0.808, 0.667, 0.192, 0.23, 0.094, 0.838, 0.692, 0.248, 0.085, 0.738, 0.094, 0.345, 0.562,
  0.592, 0.571, 0.633, 0.088, 0.322, 0.273, 0.036, 0.611, 0.73, 0.173, 0.169, 0.113, 0.178, 0.852,
  0.942, 0.4, 0.025, 0.412, 0.879, 0.626, 0.383, 0.795, 0.156, 0.58, 0.819, 0.705, 0.072, 0.494,
  0.404, 0.8, 0.287, 0.448, 0.619, 0.092, 0.516, 0.17, 0.415, 0.799, 0.266, 0.071, 0.63, 0.479,
  0.348, 0.225, 0.157, 0.873, 0.271, 0.401, 0.504, 0.296, 0.238, 0.085, 0.543, 0.431, 0.51, 0.798,
  0.929, 0.834, 0.521, 0.879, 0.671, 0.079, 0.439, 0.9, 0.642, 0.398, 0.311, 0.76, 0.63, 0.813,
  0.375, 0.986, 0.951, 0.127, 0.505, 0.727, 0.041, 0.248, 0.578, 0.364, 0.703, 0.64, 0.208, 0.501,
  0.921, 0.703, 0.876, 0.288, 0.967, 0.239, 0.389, 0.521, 0.365, 0.517, 0.61, 0.825, 0.112, 0.238,
  0.919, 0.476, 0.676, 0.62, 0.669, 0.987, 0.885, 0.174, 0.303, 0.055, 0.96, 0.067, 0.735, 0.034,
  0.095, 0.191, 0.294, 0.703, 0.621, 0.33, 0.11, 0.225, 0.55, 0.908, 0.189, 0.668, 0.841, 0.44,
  0.672, 0.705, 0.98, 0.552, 0.517, 0.749, 0.59, 0.443, 0.552, 0.77, 0.035, 0.208, 0.936, 0.546,
  0.331, 0.57, 0.455, 0.359, 0.076, 0.82, 0.939, 0.046, 0.158, 0.716, 0.848, 0.236, 0.098, 0.751,
  0.502, 0.47, 0.141, 0.52, 0.938, 0.849, 0.054, 0.442, 0.5, 0.562, 0.286, 0.11, 0.963, 0.385,
  0.243, 0.905, 0.063, 0.186, 0.198, 0.079, 0.508, 0.284, 0.695, 0.802, 0.321, 0.655, 0.154, 0.03,
  0.887, 0.34, 0.304, 0.918, 0.674, 0.396, 0.759, 0.689, 0.522, 0.935, 0.499, 0.555, 0.24, 0.474,
  0.432, 0.495, 0.438, 0.66, 0.765, 0.248, 0.146, 0.886, 0.665, 0.122, 0.565, 0.763, 0.49, 0.753,
  0.415, 0.8, 0.444, 0.898, 0.607, 0.015, 0.761, 0.14, 0.953, 0.114, 0.948, 0.066, 0.491, 0.471,
  0.308, 0.568, 0.437, 0.002, 0.652, 0.634, 0.098, 0.523, 0.918, 0.621, 0.81, 0.266, 0.675, 0.092,
  0.482, 0.4, 0.19, 0.738, 0.475, 0.965, 0.319, 0.223, 0.182, 0.741, 0.08, 0.655, 0.802, 0.153,
  0.689, 0.066, 0.4, 0.859, 0.73, 0.576, 0.736, 0.662, 0.852, 0.784, 0.135, 0.954, 0.101, 0.571,
  0.565, 0.366, 0.297, 0.043, 0.972, 0.951, 0.707, 0.064, 0.212, 0.656, 0.706, 0.91, 0.869, 0.001,
  0.505, 0.877, 0.513, 0.31, 0.768, 0.392, 0.294, 0.726, 0.626, 0.777, 0.512, 0.903, 0.774, 0.699,
  0.878, 0.087, 0.646, 0.528, 0.978, 0.298, 0.682, 0.27, 0.218, 0.84, 0.031, 0.211, 0.982, 0.417,
  0.181, 0.866, 0.05, 0.422, 0.303, 0.848, 0.906, 0.36, 0.207, 0.351, 0.137, 0.683, 0.792, 0.823,
  0.302, 0.223, 0.021, 0.035, 0.698, 0.938, 0.079, 0.266, 0.367, 0.606, 0.772, 0.937, 0.837, 0.019,
  0.446, 0.488, 0.199, 0.172, 0.72, 0.742, 0.863, 0.206, 0.875, 0.959, 0.285, 0.972, 0.931, 0.772,
  0.91, 0.442, 0.742, 0.448, 0.119, 0.561, 0.854, 0.869, 0.256, 0.976, 0.209, 0.083, 0.266, 0.686,
  0.347, 0.12, 0.946, 0.02, 0.707, 0.745, 0.536, 0.349, 0.928, 0.413, 0.364, 0.055, 0.274, 0.108,
  0.31, 0.867, 0.239, 0.257, 0.467, 0.96, 0.83, 0.727, 0.062, 0.754, 0.628, 0.931, 0.433, 0.217,
  0.714, 0.579, 0.333, 0.939, 0.102, 0.065, 0.772, 0.14, 0.961, 0.373, 0.321, 0.934, 0.341, 0.627,
  0.416, 0.501, 0.265, 0.423, 0.499, 0.293, 0.082, 0.76, 0.473, 0.823, 0.218, 0.755, 0.966, 0.345,
  0.711, 0.646, 0.857, 0.616, 0.906, 0.868, 0.777, 0.216, 0.805, 0.818, 0.766, 0.161, 0.354, 0.052,
  0.647, 0.476, 0.642, 0.007, 0.575, 0.402, 0.036, 0.559, 0.281, 0.487, 0.74, 0.626, 0.675, 0.035,
  0.405, 0.991, 0.912, 0.001, 0.045, 0.725, 0.588, 0.729, 0.274, 0.545, 0.706, 0.873, 0.387, 0.733,
  0.328, 0.808, 0.699, 0.563, 0.19, 0.283, 0.905, 0.038, 0.369, 0.872, 0.415, 0.289, 0.096, 0.188,
  0.168, 0.596, 0.81, 0.259, 0.382, 0.739, 0.665, 0.511, 0.698, 0.3, 0.04, 0.762, 0.719, 0.581,
  0.578, 0.922, 0.045, 0.213, 0.34, 0.648, 0.379, 0.9, 0.581, 0.018, 0.751, 0.255, 0.053, 0.407, 0,
  0.335, 0.828, 0.77, 0.074, 0.318, 0.326, 0.505, 0.851, 0.854, 0.833, 0.316, 0.522, 0.955, 0.537,
  0.53, 0.595, 0.335, 0.368, 0.712, 0.399, 0.141, 0.8, 0.858, 0.995, 0.8, 0.166, 0.355, 0.583,
  0.182, 0.383, 0.734, 0.528, 0.665, 0.247, 0.268, 0.862, 0.224, 0.418, 0.255, 0.842, 0.716, 0.92,
  0.94, 0.321, 0.57, 0.755, 0.557, 0.186, 0.672, 0.278, 0.66, 0.733, 0.343, 0.038, 0.542, 0.203,
  0.586, 0.994, 0.477, 0.009, 0.586, 0.388, 0.253, 0.755, 0.412, 0.623, 0.615, 0.047, 0.971, 0.819,
  0.945, 0.763, 0.532, 0.903, 0.957, 0.449, 0.854, 0.553, 0.756, 0.509, 0.263, 0.72, 0.52, 0.858,
  0.803, 0.986, 0.842, 0.605, 0.59, 0.276, 0.184, 0.889, 0.092, 0.188, 0.273, 0.393, 0.561, 0.312,
  0.193, 0.467, 0.015, 0.313, 0.39, 0.078, 0.241, 0.465, 0.07, 0.625, 0.072, 0.837, 0.468, 0.118,
  0.421, 0.309, 0.199, 0.737, 0.719, 0.68, 0.525, 0.912, 0.104, 0.207, 0.619, 0.895, 0.514, 0.528,
  0.175, 0.683, 0.026, 0.472, 0.258, 0.129, 0.162, 0.627, 0.953, 0.403, 0.277, 0.145, 0.367, 0.408,
  0.865, 0.757, 0.007, 0.727, 0.942, 0.597, 0.262, 0.708, 0.009, 0.252, 0.907, 0.712, 0.781, 0.892,
  0.331, 0.768, 0.793, 0.888, 0.618, 0.797, 0.119, 0.688, 0.259, 0.405, 0.435, 0.829, 0.406, 0.975,
  0.769, 0.671, 0.251, 0.365, 0.22, 0.217, 0.124, 0.072, 0.452, 0.973, 0.523, 0.637, 0.992, 0.85,
  0.594, 0.221, 0.264, 0.584, 0.187, 0.262, 0.887, 0.785, 0.721, 0.926, 0.784, 0.998, 0.497, 0.408,
  0.169, 0.562, 0.56, 0.651, 0.659, 0.889, 0.167, 0.136, 0.824, 0.787, 0.036, 0.162, 0.644, 0.149,
  0.274, 0.85, 0.005, 0.92, 0.024, 0.966, 0.683, 0.621, 0.797, 0.367, 0.236, 0.78, 0.517, 0.496,
  0.58, 0.707, 0.486, 0.983, 0.113, 0.338, 0.874, 0.428, 0.279, 0.747, 0.088, 0.488, 0.386, 0.255,
  0.602, 0.964, 0.54, 0.007, 0.562, 0.212, 0.088, 0.511, 0.788, 0.187, 0.744, 0.329, 0.043, 0.618,
  0.579, 0.627, 0.429, 0.582, 0.181, 0.057, 0.063, 0.298, 0.174, 0.388, 0.666, 0.161, 0.547, 0.61,
  0.335, 0.967, 0.908, 0.628, 0.282, 0.403, 0.68, 0.173, 0.596, 0.063, 0.651, 0.727, 0.904, 0.033,
  0.978, 0.122, 0.757, 0.428, 0.202, 0.399, 0.156, 0.791, 0.224, 0.122, 0.121, 0.178, 0.158, 0.294,
  0.445, 0.668, 0.14, 0.123, 0.497, 0.003, 0.36, 0.963, 0.844, 0.138, 0.377, 0.401, 0.338, 0.323,
  0.264, 0.921, 0.956, 0.959, 0.669, 0.974, 0.898, 0.787, 0.296, 0.929, 0.585, 0.692, 0.68, 0.723,
  0.023, 0.409, 0.947, 0.551, 0.577, 0.98, 0.098, 0.102, 0.176, 0.156, 0.815, 0.614, 0.736, 0.709,
  0.793, 0.624, 0.496, 0.566, 0.561, 0.497, 0.04, 0.529, 0.096, 0.164, 0.342, 0.343, 0.63, 0.533,
  0.03, 0.228, 0.474, 0.325, 0.28, 0.462, 0.447, 0.936, 0.91, 0.775, 0.939, 0.915, 0.964, 0.818,
  0.935, 0.786, 0.374, 0.238, 0.16, 0.352, 0.597, 0.024, 0.124, 0.576, 0.598, 0.587, 0.32, 0.895,
  0.805, 0.888, 0.822, 0.792, 0.451, 0.759, 0.222, 0.623, 0.738, 0.598, 0.936, 0.567, 0.858, 0.233,
  0.346, 0.964, 0.073, 0.166, 0.625, 0.242, 0.441, 0.03, 0.923, 0.253, 0.912, 0.49, 0.696, 0.215,
  0.732, 0.708, 0.215, 0.665, 0.807, 0.46, 0.863, 0.75, 0.246, 0.825, 0.489, 0.503, 0.939, 0.449,
  0.271, 0.862, 0.769, 0.981, 0.7, 0.091, 0.786, 0.14, 0.523, 0.313, 0.078, 0.088, 0.339, 0.624,
  0.977, 0.614, 0.702, 0.726, 0.948, 0.214, 0.87, 0.84, 0.205, 0.098, 0.979, 0.299, 0.223, 0.073,
  0.521, 0.535, 0.418, 0.236, 0.663, 0.012, 0.358, 0.7, 0.869, 0.074, 0.652, 0.02, 0.771, 0.801,
  0.892, 0.352, 0.914, 0.341, 0.796, 0.685, 0.274, 0.995, 0.856, 0.166, 0.981, 0.018, 0.028, 0.981,
  0.92, 0.175, 0.441, 0.525, 0.664, 0.119, 0.76, 0.553, 0.329, 0.997, 0.201, 0.866, 0.456, 0.71,
  0.826, 0.052, 0.175, 0.855, 0.235, 0.297, 0.197, 0.554, 0.241, 0.849, 0.386, 0.349, 0.435, 0.761,
  0.762, 0.89, 0.78, 0.503, 0.693, 0.069, 0.526, 0.032, 0.612, 0.968, 0.408, 0.602, 0.79, 0.138,
  0.921, 0.941, 0.041, 0.695, 0.405, 0.432, 0.217, 0.057, 0.388, 0.788, 0.276, 0.294, 0.293, 0.203,
  0.174, 0.445, 0.896, 0.346, 0.989, 0.803, 0.672, 0.944, 0.245, 0.994, 0.111, 0.126, 0.477, 0.209,
  0.796, 0.779, 0.253, 0.894, 0.181, 0.215, 0.736, 0.514, 0.286, 0.176, 0.754, 0.226, 0.92, 0.396,
  0.938, 0.85, 0.759, 0.282, 0.513, 0.867, 0.773, 0.712, 0.282, 0.993, 0.216, 0.292, 0.573, 0.962,
  0.15, 0.485, 0.145, 0.667, 0.693, 0.925, 0.657, 0.817, 0.197, 0.493, 0.634, 0.122, 0.218, 0.679,
  0.457, 0.399, 0.564, 0.251, 0.409, 0.204, 0.527, 0.878, 0.391, 0.133, 0.515, 0.43, 0.463, 0.429,
  0.271, 0.661, 0.578, 0.396, 0.667, 0.877, 0.766, 0.224, 0.715, 0.47, 0.473, 0.23, 0.934, 0.937,
  0.156, 0.102, 0.118, 0.264, 0.972, 0.943, 0.498, 0.859, 0.466, 0.385, 0.196, 0.962, 0.167, 0.913,
  0.818, 0.893, 0.235, 0.074, 0.228, 0.732, 0.167, 0.737, 0.111, 0.18, 0.676, 0.358, 0.201, 0.296,
  0.064, 0.199, 0.843, 0.915, 0.679, 0.526, 0.667, 0.345, 0.076, 0.206, 0.126, 0.987, 0.383, 0.48,
  0.701, 0.949, 0.785, 0.625, 0.663, 0.743, 0.706, 0.39, 0.861, 0.278, 0.578, 0.951, 0.132, 0.022,
  0.03, 0.675, 0.546, 0.49, 0.942, 0.283, 0.383, 0.775, 0.587, 0.445, 0.15, 0.981, 0.734, 0.989,
  0.652, 0.763, 0.395, 0.157, 0.01, 0.047, 0.211, 0.388, 0.314, 0.342, 0.973, 0.534, 0.657, 0.358,
  0.138, 0.166, 0.07, 0.718, 0.622, 0.764, 0.561, 0.884, 0.399, 0.28, 0.952, 0.697, 0.387, 0.795,
  0.965, 0.513, 0.365, 0.776, 0.116, 0.837, 0.574, 0.795, 0.54, 0.265, 0.226, 0.048, 0.556, 0.602,
  0.494, 0.232, 0.698, 0.039, 0.92, 0.66, 0.973, 0.441, 0.921, 0.419, 0.316, 0.63, 0.061, 0.53,
  0.508, 0.153, 0.193, 0.462, 0.812, 0.466, 0.197, 0.687, 0.206, 0.845, 0.14, 0.051, 0.312, 0.885,
  0.466, 0.598, 0.273, 0.514, 0.865, 0.281, 0.85, 0.16, 0.334, 0.858, 0.839, 0.451, 0.509, 0.128,
  0.452, 0.849, 0.254, 0.474, 0.983, 0.861, 0.465, 0.693, 0.395, 0.897, 0.234, 0.442, 0.902, 0.777,
  0.676, 0.171, 0.543, 0.632, 0.868, 0.681, 0.772, 0.258, 0.831, 0.343, 0.309, 0.722, 0.71, 0.346,
  0.506, 0.29, 0.829, 0.599, 0.761, 0.283, 0.541, 0.512, 0.024, 0.039, 0.704, 0.094, 0.495, 0.355,
  0.186, 0.647, 0.738, 0.838, 0.684, 0.298, 0.853, 0.431, 0.776, 0.12, 0.671, 0.756, 0.376, 0.911,
  0.309, 0.326, 0.641, 0.608, 0.502, 0.881, 0.019, 0.498, 0.08, 0.857, 0.018, 0.749, 0.392, 0.089,
  0.735, 0.764, 0.737, 0.793, 0.419, 0.379, 0.958, 0.364, 0.299, 0.442, 0.67, 0.084, 0.656, 0.24,
  0.735, 0.324, 0.158, 0.512, 0.118, 0.45, 0.071, 0.288, 0.793, 0.745, 0.716, 0.41, 0.958, 0.208,
  0.168, 0.489, 0.499, 0.893, 0.703, 0.678, 0.18, 0.545, 0.952, 0.234, 0.515, 0.635, 0.393, 0.895,
  0.219, 0.234, 0.937, 0.511, 0.816, 0.911, 0.208, 0.051, 0.218, 0.764, 0.391, 0.276, 0.143, 0.458,
  0.735, 0.891, 0.216, 0.96, 0.231, 0.136, 0.325, 0.748, 0.93, 0.913, 0.256, 0.955, 0.287, 0.871,
  0.436, 0.571, 0.991, 0.354, 0.227, 0.363, 0.594, 0.909, 0.826, 0.825, 0.404, 0.029, 0.299, 0.031,
  0.538, 0.392, 0.185, 0.631, 0.897, 0.912, 0.816, 0.165, 0.082, 0.136, 0.938, 0.822, 0.577, 0.215,
  0.239, 0.302, 0.499, 0.444, 0.222, 0.4, 0.625, 0.684, 0.933, 0.13, 0.544, 0.954, 0.831, 0.236,
  0.475, 0.833, 0.035, 0.281, 0.872, 0.241, 0.825, 0.974, 0.284, 0.386, 0.06, 0.042, 0.108, 0.406,
  0.855, 0.933, 0.864, 0.437, 0.715, 0.86, 0.531, 0.419, 0.47, 0.11, 0.955, 0.45, 0.729, 0.736,
  0.445, 0.98, 0.809, 0.402, 1, 0.54, 0.1, 0.527, 0.43, 0.207, 0.193, 0.433, 0.368, 0.155, 0.26,
  0.489, 0.355, 0.455, 0.583, 0.01, 0.148, 0.425, 0.002, 0.045, 0.635, 0.066, 0.266, 0.264, 0.225,
  0.619, 0.99, 0.799, 0.749, 0.447, 0.89, 0.495, 0.57, 0.569, 0.712, 0.044, 0.732, 0.869, 0.16,
  0.034, 0.886, 0.627, 0.085, 0.595, 0.516, 0.955, 0.698, 0.876, 0.243, 0.553, 0.084, 0.056, 0.129,
  0.901, 0.974, 0.237, 0.75, 0.457, 0.581, 0.484, 0.537, 0.008, 0.376, 0.134, 0.717, 0.758, 0.06,
  0.658, 0.48, 0.882, 0.827, 0.899, 0.031, 0.237, 0.711, 0.467, 0.635, 0.532, 0.893, 0.292, 0.107,
  0.661, 0.095, 0.305, 0.016, 0.74, 0.828, 0.045, 0.444, 0.602, 0.649, 0.221, 0.555, 0.155, 0.408,
  0.129, 0.608, 0.691, 0.748, 0.191, 0.09, 0.016, 0.603, 0.345, 0.565, 0.613, 0.74, 0.306, 0.415,
  0.493, 0.485, 0.115, 0.011, 0.392, 0.874, 0.971, 0.158, 0.968, 0.181, 0.229, 0.514, 0.489, 0.815,
  0.431, 0.632, 0.675, 0.354, 0.306, 0.202, 0.056, 0.102, 0.678, 0.669, 0.012, 0.155, 0.288, 0.004,
  0.573, 0.172, 0.917, 0.068, 0.411, 0.054, 0.106, 0.44, 0.972, 0.905, 0.233, 0.711, 0.134, 0.207,
  0.985, 0.053, 0.879, 0.659, 0.51, 0.151, 0.272, 0.959, 0.827, 0.369, 0.758, 0.327, 0.828, 0.105,
  0.655, 0.2, 0.127, 0.314, 0.727, 0.36, 0.478, 0.47, 0.384, 0.911, 0.447, 0.219, 0.534, 0.975,
  0.325, 0.638, 0.071, 0.029, 0.848, 0.57, 0.013, 0.049, 0.323, 0.422, 0.675, 0.698, 0.184, 0.295,
  0.119, 0.334, 0.185, 0.801, 0.451, 0.789, 0.541, 0.762, 0.374, 0.578, 0.88, 0.041, 0.384, 0.72,
  0.34, 0.844, 0.053, 0.68, 0.69, 0.824, 0.942, 0.3, 0.827, 0.448, 0.72, 0.785, 0.654, 0.301, 0.948,
  0.777, 0.616, 0.117, 0.078, 0.324, 0.416, 0.817, 0.075, 0.645, 0.261, 0.042, 0.19, 0.097, 0.677,
  0.831, 0.891, 0.602, 0.216, 0.87, 0.361, 0.24, 0.788, 0.192, 0.682, 0.052, 0.753, 0.336, 0.113,
  0.483, 0.892, 0.023, 0.233, 0.845, 0.332, 0.068, 0.291, 0.05, 0.446, 0.569, 0.331, 0.328, 0.902,
  0.885, 0.35, 0.613, 0.382, 0.615, 0.972, 0.472, 0.989, 0.681, 0.309, 0.884, 0.835, 0.537, 0.581,
  0.177, 0.171, 0.502, 0.395, 0.337, 0.449, 0.104, 0.89, 0.961, 0.75, 0.55, 0.049, 0.56, 0.879,
  0.026, 0.166, 0.702, 0.23, 0.786, 0.279, 0.569, 0.918, 0.235, 0.8, 0.902, 0.26, 0.291, 0.648, 0.3,
  0.592, 0.081, 0.689, 0.557, 0.262, 0.133, 0.498, 0.601, 0.85, 0.437, 0.994, 0.153, 0.792, 0.649,
  0.45, 0.055, 0.127, 0.605, 0.586, 0.174, 0.559, 0.628, 0.6, 0.506, 0.9, 0.875, 0.92, 0.884, 0.483,
  0.609, 0.188, 0.111, 0.583, 0.079, 0.44, 0.849, 0.462, 0.014, 0.01, 0.141, 0.448, 0.902, 0.98,
  0.039, 0.704, 0.559, 0.555, 0.054, 0.458, 0.784, 0.836, 0.239, 0.149, 0.483, 0.368, 0.301, 0.116,
  0.077, 0.886, 0.952, 0.765, 0.625, 0.33, 0.123, 0.611, 0.313, 0.044, 0.133, 0.38, 0.99, 0.114,
  0.878, 0.459, 0.591, 0.402, 0.719, 0.718, 0.006, 0.61, 0.003, 0.512, 0.867, 0.855, 0.583, 0.016,
  0.067, 0.513, 0.44, 0.407, 0.558, 0.74, 0.65, 0.87, 0.97, 0.252, 0.544, 0.203, 0.436, 0.61, 0.893,
  0.521, 0.558, 0.718, 0.061, 0.461, 0.203, 0.836, 0.199, 0.167, 0.089, 0.681, 0.197, 0.247, 0.267,
  0.615, 0.638, 0.877, 0.202, 0.772, 0.685, 0.609, 0.79, 0.092, 0.084, 0.728, 0.662, 0.928, 0.846,
  0.248, 0.745, 0.413, 0.067, 0.855, 0.121, 0.277, 0.024, 0.547, 0.026, 0.776, 0.594, 0.745, 0.286,
  0.116, 0.723, 0.86, 0.973, 0.001, 0.31, 0.136, 1, 0.792, 0.361, 0.131, 0.516, 0.039, 0.718, 0.84,
  0.286, 0.19, 0.88, 0.896, 0.322, 0.448, 0.099, 0.076, 0.24, 0.969, 0.815, 0.427, 0.783, 0.605,
  0.46, 0.565, 0.075, 0.154, 0.502, 0.457, 0.108, 0.245, 0.059, 0.821, 0.312, 0.648, 0.518, 0.318,
  0.807, 0.323, 0.506, 0.228, 0.843, 0.394, 0.814, 0.97, 0.948, 0.284, 0.378, 0.882, 0.646, 0.638,
  0.375, 0.668, 0.032, 0.062, 0.794, 0.743, 0.612, 0.04, 0.982, 0.334, 0.159, 0.434, 0.838, 0.329,
  0.459, 0.678, 0.748, 0.909, 0.482, 0.452, 0.285, 0.923, 0.509, 0.69, 0.979, 0.268, 0.135, 0.652,
  0.43, 0.551, 0.549, 0.142, 0.704, 0.321, 0.076, 0.15, 0.92, 0.059, 0.151, 0.169, 0.858, 0.614,
  0.83, 0.137, 0.025, 0.29, 0.24, 0.432, 0.526, 0.645, 0.708, 0.417, 0.673, 0.786, 0.282, 0.692,
  0.444, 0.011, 0.731, 0.595, 0.369, 0.062, 0.636, 0.369, 0.57, 0.761, 0.136, 0.515, 0.894, 0.224,
  0.359, 0.222, 0.303, 0.152, 0.776, 0.53, 0.594, 0.434, 0.947, 0.929, 0.041, 0.538, 0.134, 0.702,
  0.744, 0.705, 0.898, 0.777, 0.925, 0.568, 0.283, 0.569, 0.365, 0.359, 0.522, 0.195, 0.46, 0.988,
  0.468, 0.003, 0.529, 0.316, 0.884, 0.4, 0.098, 0.077, 0.228, 0.082, 0.488, 0.38, 0.519, 0.684,
  0.916, 0.295, 0.422, 0.975, 0.166, 0.164, 0.071, 0.597, 0.672, 0.022, 0.186, 0.566, 0.704, 0.699,
  0.749, 0.468, 0.287, 0.124, 0.157, 0.082, 0.86, 0.294, 0.084, 0.665, 0.129, 0.996, 0.384, 0.746,
  0.907, 0.069, 0.463, 0.875, 0.556, 0.02, 0.565, 0.56, 0.036, 0.818, 0.809, 0.492, 0.917, 0.309,
  0.631, 0.143, 0.768, 0.501, 0.947, 0.359, 0.4, 0.922, 0.646, 0.505, 0.883, 0.373, 0.538, 0.524,
  0.201, 0.81, 0.77, 0.803, 0.988, 0.835, 0.655, 0.317, 0.558, 0.13, 0.349, 0.897, 0.048, 0.339,
  0.346, 0.421, 0.608, 0.658, 0.269, 0.394, 0.697, 0.742, 0.548, 0.108, 0.438, 0.409, 0.978, 0.62,
  0.999, 0.691, 0.222, 0.204, 0.623, 0.171, 0.467, 0.631, 0.687, 0.326, 0.289, 0.387, 0.818, 0.644,
  0.269, 0.219, 0.915, 0.433, 0.753, 0.042, 0.103, 0.713, 0.093, 0.319, 0.123, 0.128, 0.48, 0.289,
  0.89, 0.17, 0.81, 0.408, 0.561, 0.071, 0.826, 0.44, 0.388, 0.51, 0.547, 0.802, 0.817, 0.386, 0.65,
  0.832, 0.732, 0.223, 0.968, 0.221, 0.842, 0.181, 0.52, 0.892, 0.765, 0.906, 0.204, 0.54, 0.453,
  0.926, 0.003, 0.455, 0.33, 0.72, 0.842, 0.174, 0.5, 0.847, 0.997, 0.89, 0.226, 0.861, 0.715,
  0.821, 0.164, 0.592, 0.41, 0.7, 0.131, 0.986, 0.183, 0.743, 0.481, 0.403, 0.595, 0.753, 0.531,
  0.418, 0.997, 0.839, 0.194, 0.725, 0.428, 0.038, 0.229, 0.38, 0.077, 0.932, 0.923, 0.64, 0.982,
  0.812, 0.163, 0.601, 0.179, 0.016, 0.407, 0.808, 0.532, 0.401, 0.184, 0.617, 0.544, 0.412, 0.307,
  0.536, 0.487, 0.194, 0.66, 0.742, 0.601, 0.518, 0.642, 0.117, 0.306, 0.123, 0.072, 0.689, 0.094,
  0.033, 0.146, 0.392, 0.176, 0.463, 0.481, 0.524, 0.241, 0.694, 0.926, 0.682, 0.397, 0.126, 0.721,
  0.417, 0.673, 0.614, 0.125, 0.695, 0.574, 0.235, 0.817, 0.139, 0.834, 0.362, 0.637, 0.462, 0.643,
  0.991, 0.713, 0.297, 0.86, 0.405, 0.294, 0.441, 0.941, 0.303, 0.308, 0.035, 0.759, 0.99, 0.372,
  0.471, 0.314, 0.22, 0.662, 0.883, 0.55, 0.929, 0.261, 0.378, 0.68, 0.005, 0.436, 0.13, 0.899,
  0.705, 0.78, 0.169, 0.315, 0.863, 0.919, 0.968, 0.243, 0.491, 0.812, 0.875, 0.52, 0.688, 0.518,
  0.1, 0.034, 0.958, 0.099, 0.405, 0.354, 0.782, 0.401, 0.474, 0.767, 0.419, 0.28, 0.986, 0.77,
  0.357, 0.026, 0.108, 0.019, 0.538, 0.252, 0.87, 0.311, 0.953, 0.73, 0.592, 0.004, 0.314, 0.756,
  0.829, 0.091, 0.026, 0.945, 0.084, 0.43, 0.451, 0.504, 0.315, 0.617, 0.977, 0.176, 0.954, 0.858,
  0.151, 0.52, 0.481, 0.865, 0.782, 0.624, 0.708, 0.928, 0.446, 0.856, 0.862, 0.55, 0.671, 0.696,
  0.048, 0.982, 0.607, 0.061, 0.445, 0.523, 0.134, 0.925, 0.607, 0.089, 0.36, 0.695, 0.33, 0.123,
  0.011, 0.163, 0.317, 0.213, 0.53, 0.159, 0.398, 0.922, 0.091, 0.305, 0.967, 0.276, 0.189, 0.231,
  0.898, 0.06, 0.217, 0.911, 0.626, 0.308, 0.73, 0.37, 0.338, 0.176, 0.027, 0.943, 0.038, 0.71,
  0.35, 0.279, 0.277, 0.234, 0.446, 0.276, 0.158, 0.643, 0.274, 0.674, 0.337, 0.093, 0.157, 0.353,
  0.506, 0.357, 0.115, 0.336, 0.305, 0.17, 0.697, 0.135, 0.791, 0.56, 0.428, 0.093, 0.227, 0.779,
  0.449, 0.047, 0.336, 0.143, 0.699, 0.987, 0.519, 0.951, 0.869, 0.022, 0.183, 0.265, 0.798, 0.044,
  0.548, 0.996, 0.765, 0.101, 0.435, 0.755, 0.148, 0.165, 0.705, 0.728, 0.579, 0.016, 0.206, 0.547,
  0.037, 0.878, 0.603, 0.011, 0.389, 0.796, 0.5, 0.812, 0.178, 0.642, 0.392, 0.86, 0.097, 0.931,
  0.146, 0.606, 0.41, 0.453, 0.628, 0.589, 0.958, 0.129, 0.029, 0.329, 0.919, 0.757, 0.333, 0.182,
  0.019, 0.344, 0.967, 0.817, 0.77, 0.585, 0.252, 0.263, 0.141, 0.664, 0.382, 0.529, 0.958, 0.526,
  0.568, 0.843, 0.519, 0.876, 0.758, 0.595, 0.017, 0.801, 0.606, 0.685, 0.134, 0.36, 0.278, 0.4,
  0.584, 0.993, 0.846, 0.948, 0.777, 0.989, 0.163, 0.954, 0.615, 0.767, 0.311, 0.35, 0.37, 0.576,
  0.62, 0.648, 0.554, 0.788, 0.214, 0.683, 0.223, 0.616, 0.07, 0.738, 0.534, 0.558, 0.563, 0.378,
  0.242, 0.284, 0.161, 0.297, 0.113, 0.332, 0.218, 0.214, 0.357, 0.737, 0.682, 0.721, 0.416, 0.855,
  0.257, 0.917, 0.049, 0.221, 0.155, 0.725, 0.857, 0.121, 0.185, 0.469, 0.995, 0.096, 0.717, 0.563,
  0.68, 0.318, 0.425, 0.746, 0.93, 0.803, 0.187, 0.898, 0.27, 0.527, 0.49, 0.885, 0.539, 0.36,
  0.015, 0.633, 0.374, 0.164, 0.087, 0.989, 0.261, 0.014, 0.46, 0.171, 0.668, 0.291, 0.979, 0.165,
  0.165, 0.622, 0.422, 0.039, 0.262, 0.591, 0.472, 0.327, 0.177, 0.199, 0.888, 0.635, 0.087, 0.582,
  0.037, 0.05, 0.068, 0.629, 0.998, 0.384, 0.498, 0.765, 0.571, 0.438, 0.321, 0.097, 0.312, 0.704,
  0.626, 0.061, 0.873, 0.455, 0.006, 0.778, 0.328, 0.93, 0.338, 0.964, 0.246, 0.672, 0.234, 0.724,
  0.032, 0.751, 0.249, 0.008, 0.109, 0.132, 0.723, 0.822, 0.907, 0.422, 0.295, 0.461, 0.229, 0.479,
  0.792, 0.47, 0.987, 0.437, 0.59, 0.288, 0.348, 0.034, 0.488, 0.866, 0.064, 0.801, 0.424, 0.778,
  0.65, 0.729, 0.897, 0.03, 0.57, 0.497, 0.152, 0.847, 0.11, 0.465, 0.511, 0.011, 0.249, 0.038,
  0.634, 0.033, 0.307, 0.196, 0.808, 0.246, 0.747, 0.254, 0.031, 0.79, 0.63, 0.576, 0.556, 0.891,
  0.523, 0.636, 0.978, 0.73, 0.809, 0.179, 0.733, 0.723, 0.101, 0.158, 0.613, 0.615, 0.931, 0.452,
  0.75, 0.501, 0.674, 0.414, 0.63, 0.518, 0.349, 0.905, 0.394, 0.871, 0.753, 0.971, 0.347, 0.42,
  0.267, 0.096, 0.364, 0.526, 0.268, 0.945, 0.585, 0.899, 0.047, 0.666, 0.771, 0.163, 0.032, 0.739,
  0.618, 0.054, 0.799, 0.79, 0.314, 0.481, 0.715, 0.842, 0.127, 0.245, 0.354, 0.476, 0.277, 0.174,
  0.39, 0.41, 0.258, 0.201, 0.728, 0.214, 0.267, 0.958, 0.755, 0.761, 0.353, 0.701, 0.923, 0.058,
  0.299, 0.464, 0.937, 0.439, 0.942, 0.803, 0.038, 0.191, 0.522, 0.853, 0.401, 0.47, 0.79, 0.072,
  0.177, 0.368, 0.349, 0.9, 0.688, 0.334, 0.025, 0.606, 0.357, 0.67, 0.833, 0.65, 0.42, 0.243,
  0.017, 0.423, 0.377, 0.572, 0.823, 0.185, 0.229, 0.415, 0.406, 0.204, 0.93, 0.912, 0.356, 0.239,
  0.693, 0.442, 0.302, 0.023, 0.221, 0.836, 0.37, 0.416, 0.953, 0.876, 0.337, 0.037, 0.537, 0.633,
  0.307, 0.279, 0.295, 0.548, 0.997, 0.743, 0.957, 0.286, 0.351, 0.447, 0.145, 0.98, 0.948, 0.96,
  0.007, 0.327, 0.46, 0.575, 0.064, 0.354, 0.909, 0.707, 0.718, 0.978, 0.142, 0.506, 0.216, 0.84,
  0.984, 0.009, 0.03, 0.291, 0.012, 0.492, 0.533, 0.076, 0.83, 0.503, 0.352, 0.362, 0.168, 0.893,
  0.735, 0.424, 0.618, 0.7, 0.872, 0.857, 0.949, 0.451, 0.532, 0.575, 0.771, 0.404, 0.231, 0.009,
  0.429, 0.143, 0.255, 0.655, 0.918, 0.495, 0.63, 0.268, 0.886, 0.714, 0.089, 0.23, 0.984, 0.39,
  0.519, 0.151, 0.499, 0.304, 0.918, 0.413, 0.117, 0.434, 0.165, 0.509, 0.471, 0.345, 0.415, 0.835,
  0.043, 0.838, 0.569, 0.758, 0.64, 0.21, 0.801, 0.132, 0.391, 0.178, 0.796, 0.72, 0.35, 0.942,
  0.432, 0.678, 0.619, 0.731, 0.538, 0.065, 0.805, 0.647, 0.935, 0.48, 0.152, 0.441, 0.707, 0.746,
  0.393, 0.272, 0.659, 0.676, 0.397, 0.593, 0.426, 0.732, 0.82, 0.073, 0.867, 0.103, 0.327, 0.194,
  0.285, 0.184, 0.362, 0.495, 0.574, 0.314, 0.454, 0.293, 0.943, 0.823, 0.35, 0.363, 0.278, 0.932,
  0.697, 0.521, 0.873, 0.462, 0.665, 0.067, 0.068, 0.862, 0.39, 0.097, 0.184, 0.113, 0.172, 0.112,
  0.438, 0.776, 0.203, 0.821, 0.29, 0.538, 0.267, 0.937, 0.525, 0.423, 0.629, 0.565, 0.683, 0.033,
  0.501, 0.241, 0.857, 0.125, 0.162, 0.092, 0.931, 0.179, 0.152, 0.785, 0.468, 0.986, 0.603, 0.602,
  0.087, 0.69, 0.608, 0.856, 0.134, 0.42, 0.5, 0.992, 0.99, 0.105, 0.734, 0.541, 0.128, 0.017,
  0.372, 0.247, 0.231, 0.311, 0.123, 0.925, 0.846, 0.114, 0.086, 0.895, 0.326, 0.308, 0.905, 0.418,
  0.381, 0.766, 0.481, 0.873, 0.361, 0.624, 0.103, 0.681, 0.078, 0.285, 0.214, 0.397, 0.491, 0.271,
  0.639, 0.444, 0.462, 0.06, 0.021, 0.316, 0.212, 0.523, 0.592, 0.028, 0.677, 0.674, 0.927, 0.713,
  0.891, 0.875, 0.815, 0.363, 0.279, 0.507, 0.643, 0.6, 0.947, 0.426, 0.655, 0.369, 0.659, 0.538,
  0.37, 0.491, 0.104, 0.915, 0.483, 0.12, 0.814, 0.758, 0.156, 0.542, 0.888, 0.897, 0.425, 0.724,
  0.361, 0.237, 0.636, 0.533, 0.604, 0.42, 0.605, 0.322, 0.588, 0.303, 0.997, 0.27, 0.182, 0.773,
  0.07, 0.837, 0.924, 0.239, 0.81, 0.566, 0.633, 0.087, 0.692, 0.657, 0.06, 0.721, 0.259, 0.834,
  0.6, 0.141, 0.472, 0.075, 0.198, 0.233, 0.262, 0.691, 0.381, 0.604, 0.447, 0.726, 0.624, 0.564,
  0.241, 0.602, 0.435, 0.059, 0.963, 0.421, 0.687, 0.959, 0.714, 0.418, 0.584, 0.257, 0.198, 0.022,
  0.102, 0.895, 0.555, 0.525, 0.712, 0.5, 0.117, 0.657, 0.56, 0.962, 0.856, 0.227, 0.491, 0.332,
  0.805, 0.025, 0.056, 0.001, 0.965, 0.61, 0.017, 0.722, 0.013, 0.076, 0.563, 0.412, 0.12, 0.778,
  0.833, 0.209, 0.724, 0.189, 0.139, 0.243, 0.486, 0.214, 0.507, 0.191, 0.881, 0.407, 0.237, 0.405,
  0.081, 0.289, 0.115, 0.365, 0.609, 0.605, 0.497, 0.26, 0.073, 0.76, 0.043, 0.83, 0.924, 0.555,
  0.976, 0.081, 0.427, 0.648, 0.923, 0.287, 0.131, 0.336, 0.296, 0.56, 0.5, 0.258, 0.852, 0.456,
  0.103, 0.295, 0.304, 0.742, 0.515, 0.138, 0.192, 0.351, 0.292, 0.717, 0.511, 0.733, 0.196, 0.914,
  0.482, 0.957, 0.435, 0.983, 0.339, 0.116, 0.543, 0.604, 0.304, 0.356, 0.272, 0.632, 0.809, 0.883,
  0.557, 0.355, 0.281, 0.324, 0.056, 0.887, 0.928, 0.708, 0.368, 0.269, 0.792, 0.024, 0.42, 0.58,
  0.868, 0.305, 0.408, 0.246, 0.494, 0.995, 0.745, 0.211, 0.21, 0.214, 0.435, 0.405, 0.242, 0.149,
  0.112, 0.492, 0.732, 0.273, 0.818, 0.01, 0.389, 0.741, 0.854, 0.963, 0.264, 0.38, 0.093, 0.109,
  0.077, 0.303, 0.386, 0.345, 0.804, 0.751, 0.372, 0.501, 0.564, 0.871, 0.019, 0.362, 0.435, 0.3,
  0.615, 0.515, 0.945, 0.601, 0.593, 0.723, 0.553, 0.977, 0.319, 0.229, 0.608, 0.368, 0.328, 0.946,
  0.29, 0.571, 0.487, 0.784, 0.22, 0.609, 0.319, 0.865, 0.322, 0.315, 0.398, 0.445, 0.743, 0.517,
  0.841, 0.85, 0.429, 0.525, 0.088, 0.443, 0.342, 0.609, 0.673, 0.073, 0.874, 0.195, 0.54, 0.663,
  0.471, 0.055, 0.684, 0.804, 0.669, 0.67, 0.936, 0.279, 0.467, 0.502, 0.281, 0.933, 0.27, 0.066,
  0.677, 0.446, 0.008, 0.027, 0.548, 0.584, 0.027, 0.461, 0.957, 0.051, 0.93, 0.669, 0.69, 0.874,
  0.708, 0.899, 0.254, 0.672, 0.868, 0.275, 0.912, 0.616, 0.968, 0.932, 0.91, 0.826, 0.18, 0.893,
  0.42, 0.147, 0.049, 0.219, 0.196, 0.139, 0.132, 0.716, 0.657, 0.262, 0.479, 0.176, 0.985, 0.298,
  0.594, 0.107, 0.839, 0.119, 0.023, 0.75, 0.767, 0.767, 0.701, 0.461, 0.469, 0.464, 0.49, 0.439,
  0.075, 0.132, 0.908, 0.766, 0.106, 0.977, 0.454, 0.832, 0.631, 0.481, 0.819, 0.531, 0.762, 0.872,
  0.198, 0.078, 0.667, 0.319, 0.424, 0.549, 0.709, 0.573, 0.904, 0.41, 0.773, 0.643, 0.998, 0.425,
  0.153, 0.414, 0.744, 0.278, 0.844, 0.004, 0.621, 0.568, 0.476, 0.773, 0.868, 0.624, 0.69, 0.355,
  0.939, 0.64, 0.126, 0.188, 0.384, 0.421, 0.379, 0.808, 0.552, 0.485, 0.07, 0.45, 0.832, 0.917,
  0.394, 0.788, 0.998, 0.762, 0.798, 0.391, 0.063, 0.398, 0.747, 0.574, 0.217, 0.675, 0.559, 0.275,
  0.503, 0.125, 0.058, 0.53, 0.733, 0.553, 0.209, 0.795, 0.765, 0.517, 0.587, 0.769, 0.524, 0.706,
  0.455, 0.813, 0.138, 0.489, 0.877, 0.232, 0.848, 0.71, 0.546, 0.032, 0.7, 0.493, 0.937, 0.852,
  0.744, 0.721, 0.045, 0.913, 0.564, 0.036, 0.494, 0.475, 0.772, 0.722, 0.882, 0.918, 0.188, 0.289,
  0.147, 0.464, 0.025, 0.121, 0.61, 0.564, 0.222, 0.837, 0.701, 0.263, 0.033, 0.46, 0.752, 0.976,
  0.696, 0.27, 0.159, 0.648, 0.664, 0.543, 0.133, 0.73, 0.022, 0.45, 0.69, 0.268, 0.471, 0.315,
  0.277, 0.417, 0.168, 0.516, 0.358, 0.686, 0.678, 0.851, 0.008, 0.552, 0.667, 0.021, 0.546, 0.377,
  0.558, 0.741, 0.772, 0.108, 0.067, 0.566, 0.333, 0.672, 0.856, 0.595, 0.473, 0.342, 0.888, 0.304,
  0.245, 0.266, 0.487, 0.153, 0.945, 0.574, 0.611, 0.573, 0.478, 0.415, 0.293, 0.178, 0.952, 0.411,
  0.638, 0.717, 0.137, 0.915, 0.662, 0.847, 0.912, 0.804, 0.005, 0.564, 0.397, 0.674, 0.758, 0.662,
  0.985, 0.754, 0.465, 0.983, 0.832, 0.679, 0.926, 0.274, 0.88, 0.695, 0.249, 0.19, 0.484, 0.938,
  0.292, 0.224, 0.487, 0.932, 0.589, 0.98, 0.969, 0.535, 0.603, 0.668, 0.072, 0.909, 0.702, 0.271,
  0.34, 1, 0.701, 0.548, 0.228, 0.775, 0.658, 0.86, 0.107, 0.615, 0.536, 0.806, 0.147, 0.47, 0.661,
  0.6, 0.492, 0.05, 0.316, 0.847, 0.348, 0.506, 0.443, 0.623, 0.576, 0.058, 0.728, 0.232, 0.993,
  0.046, 0.767, 0.728, 0.251, 0.371, 0.021, 0.244, 0.773, 0.454, 0.589, 0.746, 0.742, 0.783, 0.456,
  0.783, 0.396, 0.94, 0.9, 0.471, 0.458, 0.99, 0.173, 0.94, 0.411, 0.967, 0.263, 0.002, 0.022,
  0.694, 0.29, 0.183, 0.664, 0.399, 0.498, 0.38, 0.378, 0.035, 0.711, 0.073, 0.285, 0.782, 0.044,
  0.308, 0.348, 0.996, 0.124, 0.64, 0.582, 0.78, 0.623, 0.6, 0.296, 0.139, 0.799, 0.616, 0.457,
  0.426, 0.145, 0.056, 0.409, 0.263, 0.62, 0.359, 0.11, 0.509, 0.347, 0.066, 0.335, 0.34, 0.969,
  0.301, 0.366, 0.713, 0.945, 0.728, 0.845, 0.204, 0.62, 0.231, 0.13, 0.835, 0.32, 0.116, 0.551,
  0.659, 0.512, 0.616, 0.794, 0.484, 0.774, 0.824, 0.252, 0.13, 0.381, 0.154, 0.183, 0.192, 0.881,
  0.925, 0.213, 0.423, 0.793, 0.344, 0.582, 0.472, 0.61, 0.752, 0.666, 0.476, 0.284, 0.283, 0.349,
  0.596, 0.671, 0.101, 0.051, 0.412, 0.249, 0.932, 0.094, 0.906, 0.422, 0.935, 0.593, 0.226, 0.956,
  0.199, 0.07, 0.747, 0.144, 0.095, 0.286, 0.629, 0.617, 0.59, 0.572, 0.629, 0.811, 0.339, 0.78,
  0.109, 0.99, 0.841, 0.143, 0.849, 0.6, 0.806, 0.37, 0.014, 0.9, 0.567, 0.965, 0.639, 0.356, 0.126,
  0.421, 0.745, 0.94, 0.799, 0.154, 0.275, 0.877, 0.683, 0.731, 0.474, 0.317, 0.357, 0.095, 0.28,
  0.715, 0.302, 0.107, 0.21, 0.362, 0.76, 0.114, 0.364, 0.411, 0.686, 0.244, 0.993, 0.88, 0.146,
  0.211, 0.922, 0.228, 0.379, 0.058, 0.702, 0.864, 0.96, 0.804, 0.88, 0.177, 0.605, 0.536, 0.356,
  0.591, 0.755, 0.117, 0.594, 0.998, 0.138, 0.987, 0.028, 0.687, 0.67, 0.233, 0.807, 0.49, 0.707,
  0.552, 0.26, 0.879, 0.253, 0.32, 0.949, 0.668, 0.771, 0.391, 0.32, 0.12, 0.339, 0.739, 0.325,
  0.259, 0.574, 0.559, 0.886, 0.319, 0.562, 0.211, 0.952, 0.476, 0.246, 0.389, 0.469, 0.057, 0.016,
  0.812, 0.818, 0.62, 0.404, 0.122, 0.957, 0.739, 0.048, 0.121, 0.495, 0.965, 0.128, 0.785, 0.635,
  0.068, 0.378, 0.091, 0.226, 0.998, 0.256, 0.058, 0.694, 0.559, 0.846, 0.249, 0.49, 0.148, 0.106,
  0.366, 0.226, 0.645, 0.236, 0.629, 0.139, 0.261, 0.221, 0.404, 0.944, 0.759, 0.654, 0.825, 0.679,
  0.585, 0.915, 0.055, 0.774, 0.029, 0.486, 0.913, 0.084, 0.617, 0.966, 0.366, 0.026, 0.518, 0.333,
  0.424, 0.896, 0.125, 0.699, 0.406, 0.978, 0.943, 0.794, 0.969, 0.773, 0.047, 0.787, 0.252, 0.645,
  0.621, 0.133, 0.524, 0.98, 0.625, 0.251, 0.545, 0.107, 0.495, 0.972, 0.224, 0.114, 0.753, 0.943,
  0.205, 0.708, 0.164, 0.695, 0.344, 0.524, 0.88, 0.198, 0.565, 0.663, 0.775, 0.006, 0.198, 0.816,
  0.48, 0.685, 0.703, 0.162, 0.634, 0.21, 0.899, 0.561, 0.333, 0.542, 0.365, 0.741, 0.241, 0.504,
  0.532, 0.991, 0.604, 0.342, 0.111, 0.148, 0.649, 0.028, 0.382, 0.008, 0.507, 0.74, 0.363, 0.085,
  0.648, 0.806, 0.006, 0.411, 0.395, 0.828, 0.748, 0.466, 0.539, 0.97, 0.754, 0.105, 0.377, 0.482,
  0.813, 0.572, 0.768, 0.426, 0.37, 0.006, 0.41, 0.709, 0.95, 0.185, 0.715, 0.965, 0.122, 0.273,
  0.161, 0.888, 0.953, 0.714, 0.612, 0.009, 0.438, 0.995, 0.645, 0.835, 0.41, 0.802, 0.194, 0.171,
  0.942, 0.788, 0.22, 0.59, 0.677, 0.045, 0.343, 0.737, 0.754, 0.764, 0.82, 0.21, 0.171, 0.385,
  0.975, 0.573, 0.298, 0.152, 0.925, 0.956, 0.083, 0.846, 0.75, 0.872, 0.402, 0.118, 0.609, 0.694,
  0.923, 0.926, 0.18, 0.211, 0.654, 0.795, 0.556, 0.035, 0.464, 0.469, 0.813, 0.592, 0.751, 0.739,
  0.881, 0.451, 0.459, 0.529, 0.209, 0.318, 0.38, 0.898, 0.945, 0.246, 0.644, 0.254, 0.684, 0.22,
  0.961, 0.308, 0.94, 0.091, 0.855, 0.235, 0.323, 0.131, 0.994, 0.857, 0.478, 0.475, 0.212, 0.875,
  0.098, 0.815, 0.336, 0.845, 0.536, 0.988, 0.84, 0.282, 0.904, 0.311, 0.461, 0.535, 0.848, 0.75,
  0.043, 0.152, 0.258, 0.949, 0.941, 0.961, 0.376, 0.344, 0.51, 0.271, 0.821, 0.78, 0.781, 0.175,
  0.329, 0.007, 0.555, 0.059, 0.493, 0.641, 0.881, 0.351, 0.588, 0.791, 0.956, 0.507, 0.828, 0.135,
  0.999, 0.141, 0.864, 0.692, 0.789, 0.597, 0.508, 0.238, 0.94, 0.783, 0.974, 0.053, 0.974, 0.103,
  0.37, 0.613, 0.001, 0.668, 0.865, 0.76, 0.328, 0.847, 0.724, 0.798, 0.936, 0.977, 0.756, 0.494,
  0.676, 0.104, 0.563, 0.589, 0.96, 0.88, 0.052, 0.182, 0.613, 0.074, 0.311, 0.696, 0.549, 0.147,
  0.443, 0.58, 0.066, 0.424, 0.692, 0.726, 0.634, 0.658, 0.154, 0.535, 0.347, 0.403, 0.427, 0.219,
  0.273, 0.66, 0.232, 0.545, 0.838, 0.933, 0.202, 0.244, 0.082, 0.48, 0.52, 0.353, 0.579, 0.309,
  0.97, 0.825, 0.698, 0.908, 0.375, 0.439, 0.017, 0.95, 0.824, 0.982, 0.326, 0.527, 0.572, 0.35,
  0.43, 0.895, 0.385, 0.591, 0.741, 0.89, 0.257, 0.848, 0.048, 0.343, 0.159, 0.157, 0.618, 0.532,
  0.639, 0.709, 0.579, 0.366, 0.953, 0.398, 0.194, 0.635, 0.465, 0.866, 0.902, 0.069, 0.389, 0.597,
  0.427, 0.854, 0.531, 0.795, 0.533, 0.522, 0.118, 0.514, 0.281, 0.845, 0.845, 0.031, 0.152, 0.34,
  0.599, 0.999, 0.935, 0.061, 0.062, 0.105, 0.842, 0.23, 0.905, 0.851, 0.757, 0.864, 0.478, 0.012,
  0.903, 0.146, 0.01, 0.985, 0.426, 0.938, 0.768, 0.97, 0.982, 0.86, 0.822, 0.254, 0.196, 0.682,
  0.082, 0.39, 0.504, 0.275, 0.332, 0.181, 0.189, 0.805, 0.282, 0.469, 0.568, 0.7, 0.917, 0.276,
  0.105, 0.181, 0.374, 0.554, 0.232, 0.331, 0.312, 0.8, 0.542, 0.037, 0.508, 0.947, 0.551, 0.031,
  0.907, 0.404, 0.878, 0.794, 0.677, 0.144, 0.915, 0.446, 0.231, 0.139, 0.876, 0.187, 0.465, 0.558,
  0.31, 0.636, 0.547, 0.421, 0.045, 0.436, 0.904, 0.91, 0.109, 0.15, 0.294, 0.632, 0.67, 0.528,
  0.473, 0.971, 0.144, 0.292, 0.321, 0.97, 0.433, 0.844, 0.652, 0.992, 0.713, 0.577, 0.162, 0.372,
  0.238, 0.013, 0.128, 0.546, 0.604, 0.291, 0.887, 0.299, 0.591, 0.04, 0.722, 0.402, 0.898, 0.287,
  0.803, 0.262, 0.758, 0.137, 0.781, 0.06, 0.542, 0.755, 0.679, 0.812, 0.393, 0.452, 0.2, 0.317,
  0.541, 0.113, 0.191, 0.539, 0.164, 0.072, 0.371, 0.832, 0.036, 0.334, 0.278, 0.536, 0.108, 0.821,
  0.581, 0.039, 0.685, 0.582, 0.556, 0.981, 0.505, 0.964, 0.05, 0.472, 0.037, 0.08, 0.002, 0.725,
  0.614, 0.106, 0.883, 0.568, 0.468, 0.725, 0.927, 0.15, 0.42, 0.995, 0.935, 0.734, 0.591, 0.483,
  0.649, 0.098, 0.355, 0.944, 0.998, 0.456, 0.144, 0.717, 0.168, 0.192, 0.049, 0.952, 0.16, 0.486,
  0.359, 0.174, 0.258, 0.802, 0.673, 0.996, 0.03, 0.25, 0.374, 0.492, 0.454, 0.842, 0.859, 0.131,
  0.1, 0.048, 0.95, 0.255, 0.521, 0.919, 0.287, 0.51, 0.127, 0.081, 0.887, 0.227, 0.33, 0.216,
  0.265, 0.16, 0.313, 0.16, 0.361, 0.509, 0.232, 0.111, 0.988, 0.078, 0.272, 0.904, 0.658, 0.988,
  0.783, 0.848, 0.458, 0.177, 0.155, 0.169, 0.79, 0.373, 0.134, 0.338, 0.092, 0.206, 0.249, 0.236,
  0.65, 0.338, 0.244, 0.642, 0.984, 0.034, 0.33, 0.665, 0.351, 0.069, 0.828, 0.115, 0.195, 0.517,
  0.896, 0.554, 0.052, 0.681, 0.161, 0.469, 0.477, 0.797, 0.633, 0.644, 0.053, 0.496, 0.578, 0.902,
  0.644, 0.406, 0.367, 0.795, 0.985, 0.459, 0.762, 0.244, 0.661, 0.782, 0.89, 0.021, 0.121, 0.663,
  0.193, 0.549, 0.27, 0.431, 0.269, 0.901, 0.206, 0.292, 0.127, 0.182, 0.908, 0.83, 0.789, 0.806,
  0.093, 0.752, 0.969, 0.867, 0.959, 0.313, 0.463, 0.745, 0.274, 0.853, 0.985, 0.057, 0.722, 0.938,
  0.325, 0.914, 0.588, 0.714, 0.015, 0.466, 0.335, 0.748, 0.09, 0.95, 0.322, 0.919, 0.345, 0.254,
  0.955, 0.229, 0.212, 0.018, 0.193, 0.966, 0.028, 0.276, 0.225, 0.752, 0.841, 0.465, 0.516, 0.621,
  0.012, 0.179, 0.599, 0.697, 0.157, 0.151, 0.302, 0.822, 0.289, 0.515, 0.724, 0.248, 0.504, 0.081,
  0.867, 0.046, 0.366, 0.266, 0.787, 0.514, 0.916, 0.301, 0.732, 0.115, 0.637, 0.798, 0.286, 0.257,
  0.965, 0.596, 0.836, 0.423, 0.204, 0.908, 0.789, 0.865, 0.944, 0.588, 0.596, 0.554, 0.984, 0.058,
  0.839, 0.685, 0.872, 0.814, 0.109, 0.743, 0.178, 0.005, 0.394, 0.744, 0.215, 0.43, 0.322, 0.83,
  0.374, 0.607, 0.154, 0.831, 0.109, 0.499, 0.775, 0.53, 0.02, 0.014, 0.395, 0.543, 0.004, 0.205,
  0.66, 0.453, 0.718, 0.176, 0.966, 0.401, 0.65, 0.316, 0.496, 0.95, 0.769, 0.2, 0.927, 0.611,
  0.691, 0.019, 0.025, 0.074, 0.802, 0.36, 0.323, 0.029, 0.918, 0.656, 0.335, 0.779, 0.911, 0.45,
  0.55, 0.372, 0.048, 0.631, 0.353, 0.047, 0.006, 0.388, 0.526, 0.347, 0.45, 0.817, 0.194, 0.909,
  0.873, 0.507, 0.208, 0.431, 0.722, 0.673, 0.453, 0.641, 0.005, 0.502, 0.884, 0.082, 0.484, 0.204,
  0.05, 0.114, 0.531, 0.71, 0.074, 0.398, 0.67, 0.611, 0.381, 0.946, 0.655, 0.365, 0.973, 0.505,
  0.004, 0.865, 0.195, 0.439, 0.809, 0.094, 0.099, 0.715, 0.725, 0.211, 0.089, 0.236, 0.598, 0.614,
  0.987, 0.551, 0.142, 0.54, 0.304, 0.251, 0.088, 0.956, 0.475, 0.466, 0.632, 0.534, 0.08, 0.205,
  0.199, 0.518, 0.478, 0.256, 0.927, 0.017, 0.259, 0.119, 0.544, 0.324, 0.623, 0.647, 0.183, 0.508,
  0.666, 0.184, 0.25, 0.702, 0.583, 0.852, 0.079, 0.224, 0.02, 0.814, 0.28, 0.921, 0.541, 0.475,
  0.256, 0.268, 0.213, 0.317, 0.07, 0.014, 0.713, 0.976, 0.545, 0.64, 0.125, 0.963, 0.142, 0.385,
  0.693, 0.138, 0.331, 0.658, 0.153, 0.637, 0.51, 0.337, 0.189, 0.975, 0.955, 0.838, 0.188, 0.482,
  0.929, 0.454, 0.636, 0.393, 0.74, 0.172, 0.136, 0.027, 0.933, 0.068, 0.83, 0.967, 0.68, 0.142,
  0.859, 0.062, 0.261, 0.009, 0.861, 0.077, 0.478, 0.844, 0.384, 0.346, 0.089, 0.485, 0.729, 0.386,
  0.598, 0.87, 0.973, 0.924, 0.612, 0.688, 0.894, 0.325, 0.995, 0.748, 0.823, 0.013, 0.997, 0.82,
  0.843, 0.438, 0.82, 0.835, 0.112, 0.611, 0.477, 0.797, 0.928, 0.341, 0.373, 0.302, 0.381, 0.149,
  0.902, 0.778, 0.385, 0.156, 0.425, 0.092, 0.126, 0.212, 0.542, 0.132, 0.903, 0.605, 0.352, 0.046,
  0.858, 0.916, 0.337, 0.768, 0.458, 0.453, 0.432, 0.932, 0.652, 0.94, 0.696, 0.65, 0.063, 0.972,
  0.577, 0.99, 0.336, 0.05, 0.419, 0.617, 0.87, 0.705, 0.067, 0.086, 0.479, 0.148, 0.464, 0.007,
  0.3, 0.341, 0.429, 0.82, 0.734, 0.694, 0.651, 0.378, 0.883, 0.326, 0.827, 0.584, 0.234, 0.334,
  0.533, 0.567, 0.413, 0.315, 0.629, 0.653, 0.924, 0.324, 0.172, 0.653, 0.485, 0.883, 0.816, 0.976,
  0.802, 0.828, 0.947, 0.575, 0.147, 0.783, 0.375, 0.604, 0.053, 0.519, 0.891, 0.853, 0.186, 0.587,
  0.249, 0.407, 0.643, 0.957, 0.065, 0.437, 0.999, 0.599, 0.244, 0.417, 0.766, 0.798, 0.486, 0.852,
  0.353, 0.44, 0.781, 0.885, 0.507, 0.341, 0.993, 0.238, 0.711, 0.332, 0.852, 0.725,
];
