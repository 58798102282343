var nftAutoSearch = [
  "e3izy-jiaaa-aaaah-qacbq-cai", 
  "uzhxd-ziaaa-aaaah-qanaq-cai", 
  "tde7l-3qaaa-aaaah-qansa-cai", 
  "gevsk-tqaaa-aaaah-qaoca-cai", 
  "owuqd-dyaaa-aaaah-qapxq-cai", 
  "nbg4r-saaaa-aaaah-qap7a-cai",
  "jzg5e-giaaa-aaaah-qaqda-cai", 
  "qcg3w-tyaaa-aaaah-qakea-cai", 
  "bxdf4-baaaa-aaaah-qaruq-cai", 
  "d3ttm-qaaaa-aaaai-qam4a-cai", 
  "3db6u-aiaaa-aaaah-qbjbq-cai", 
  "73xld-saaaa-aaaah-qbjya-cai", 
  "kss7i-hqaaa-aaaah-qbvmq-cai", 
  "k4qsa-4aaaa-aaaah-qbvnq-cai", 
  "uwroj-kaaaa-aaaaj-qabxa-cai",
  "njgly-uaaaa-aaaah-qb6pa-cai",
  "cihkf-qyaaa-aaaah-qb7jq-cai",
  "sr4qi-vaaaa-aaaah-qcaaq-cai",
  "q6hjz-kyaaa-aaaah-qcama-cai",
  "xkbqi-2qaaa-aaaah-qbpqq-cai",
  "ahl3d-xqaaa-aaaaj-qacca-cai",
];
var trustedCanisters = ["uzhxd-ziaaa-aaaah-qanaq-cai"].concat(nftAutoSearch);
export default {
  NFT_AUTOSEARCH : nftAutoSearch,
  TURSTED : trustedCanisters,
};